html {
  height: 100%;
}

body {
  margin: 0;
  /* font-family: "Avenir Next"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black) !important;
}
body,
input,
h1,
h2,
h3,
p,
button,
textarea {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
  letter-spacing: 0.5px !important;
}

h1,
h2,
h3 {
  font-weight: 400 !important;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
}

h1 {
  font-size: 28px;
}

p {
  font-size: 1.1em;
  line-height: 1.8em;
}

/* section {
  margin-left: auto;
  margin-right: auto;

  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
  padding-top: 230px;

} */

div.section {
  margin-bottom: 35px;
}

code {
  color: #e83e8c;
  word-break: break-word;
  font-size: 87.5%;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  background-color: #fafafa;
  padding: 4px;
  border-radius: 5px;
}

/* input {
  border-radius: 0px !important;
  padding: 10px 12px !important;
} */

/* input:focus {
  background-color: #b3d5ff !important;
} */

h3 {
  text-transform: uppercase !important;
  font-size: 10pt !important;
}

.ui.black.button {
  background-color: var(--primary) !important;
}

h1,
h2,
h3 {
  color: var(--black) !important;
}

.ui.label {
  /* color: #fff!important;
  background-color: #37352e!important; */
  font-size: 0.9em !important;
  font-weight: lighter !important;

  background-color: var(--bg-grey) !important;
  border: solid 1px var(--border-grey) !important;
}

.ui.modal > .actions {
  background-color: #fff !important;
  border: none !important;
}

.ui.modal > .header {
  border: none !important;
}

.ui.search .prompt {
  border-radius: 4px !important;
  width: 300px !important;
}

#content
  > div.fx.pageHeader
  > div
  > div:nth-child(3)
  > div:nth-child(1)
  > div
  > div.results.transition.visible {
  width: 400px;
}

.ui.labeled.icon.button > .icon,
.ui.labeled.icon.buttons > .button > .icon {
  background-color: transparent !important;
}

a {
  color: var(--primary) !important;
}

a:hover {
  filter: brightness(1.2) !important;
}

button:hover {
  filter: brightness(1.1) !important;
}

.ui.modal {
  padding: 10px;
  /* box-shadow: 0px 0px 5px 0px #fff!important; */
}

.menu > .item > a {
  color: rgba(0, 0, 0, 0.87) !important;
}

button.clndr-cell {
  border: none !important;
}

input::-webkit-calendar-picker-indicator {
  color: #fff;
}
