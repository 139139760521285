#sidebar {
  /* background-color: #282d39; */
  /* background-color: var(--bg-grey); */
  background-color: var(--bg-grey);
  border-right: dotted 1px var(--border-grey);
  border-bottom: dotted 1px var(--border-grey);
  border-radius: 0px 0px 5px 0px;
  /* filter: invert(0.9); */
  /* padding-bottom: 30px; */
  padding-top: 10px;
  position: fixed;
  min-width: 63px;
  z-index: 999;
  height: 100vh;
  /* box-shadow: -10px 0px 10px 10px var(--border-grey) ; */
}

#sidebar div.sideMenuItem {
  text-align: center;

  min-height: 50px;

  padding-top: 15px;
  /* color: #838b93; */

  color: rgb(97, 96, 95);
  font-size: 21px;
  font-weight: lighter;
  border-radius: 5px;
  margin: 4px 6px;
}

#sidebar div.sideMenuItem:hover {
  /* background-color: #161a24; */
  /* background-color: #14120814;

  color: #61605f; */

  cursor: pointer;

  color: var(--primary);
}

#sidebar div.sideMenuItem.active {
  /* color: #61605f; */
  /* background-color: #161a24; */
  /* background-color: #14120814; */
  color: var(--primary);
}

#sidebar .disabled {
  /* color: #61605f; */
  /* background-color: #161a24; */
  /* background-color: #14120814; */
  filter: brightness(2);
}

#sidebar .disabled:hover {
  cursor: grabbing !important;
  color: rgb(97, 96, 95) !important;
}

#sidebar > hr {
  width: 70%;
}

#sidebar > div.logo:hover img {
  border: solid 2px var(--primary) !important;
}
