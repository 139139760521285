.fx.fn-board {
  display: flex;
  /* flex: 1 1 0px; */
  justify-content: space-between;
  max-width: 100%;
  /* border-top: dotted 1px var(--border-grey); */
  background-color: #fcfcfc;
}

.fx.fn-board > .fn-step {
  /* width: 100%; */

  /* margin-right: 1px; */

  /* background-color: var(--bg-grey); */
  margin-left: -1px;
  box-shadow: 0 2px 4px 0 rgba(152, 153, 155, 0.15);

  flex: 1 1 0;
  width: 0;
  /* border-radius: 4px; */
}
/* .fx.fn-board > .fn-step:first-child {
  margin-left: 5px;
} */

.fx.fn-board > .fn-step > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px; */
  /* border-radius: 3px; */
  /* border: solid 1px #1d222d; */
  /* color: #fff; */
  /* background-color: var(--bg-grey); */
  /* border-bottom: solid 1px var(--border-grey);
  border-right: solid 1px var(--border-grey); */

  border-right: dotted 1px var(--border-grey);
  border-top: dotted 1px var(--border-grey);
  border-bottom: dotted 1px var(--border-grey);
  padding: 15px 12px;

  min-height: 70px;

  text-align: center;
  /* margin: 0px -1px; */

  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: initial; */

  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(152, 153, 155, 0.15);

  /* border-bottom: solid 1px #e8e8e8; */
}

.fx.fn-board > .fn-step > div:first-child > h2 {
  font-size: 12px;
  font-weight: bold !important;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;

  /* text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
  /* color: #767676; */
}

.fx.fn-board > .fn-step > div:last-child {
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100vh;
  background-color: #fcfcfc;
}

.fx.card {
  background-color: #fff;
  /* background-color: #fcfcfc; */

  margin-bottom: 10px;
  min-height: 20px;

  width: 100%;

  cursor: pointer !important;

  border-radius: 3px;
  box-shadow: 0 5px 20px 0 var(--border-grey), 0 0 0 1px var(--border-grey);
  transition: box-shadow 0.1s ease, transform 0.1s ease;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow: visible;
  white-space: pre-line;
  text-overflow: initial;
  padding: 2px;
}

.fx.card:hover {
  /* box-shadow: var(--active-shadow); */
  box-shadow: 0 1px 5px 0 var(--border-grey), 0 0px 1px 1px var(--border-grey);
}
