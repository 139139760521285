.fx.data-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;

  /* border-radius: 5px;
  border-left: solid 1px var(--border-grey);
  border-right: solid 1px var(--border-grey);
  border-bottom: solid 1px var(--border-grey); */
}

.fx.data-table th {
  /* border-left: solid 1px var(--border-grey); */
  border-bottom: solid 1px var(--border-grey);
  border-top: solid 1px var(--border-grey);

  font-size: 12px;

  color: #888;

  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: left;
  padding: 5px 20px;
}

.fx.data-table th:first-child {
  /* border-radius: 3px 0 0 0; */
  border-left: none;
}

/* .fx.data-table th:last-child {
 border-radius: 0 3px 0 0;
} */

.fx.data-table tr {
  border-bottom: solid 1px var(--border-grey);
  text-align: left;
}

.fx.data-table tr:last-child {
  border-bottom: none !important;
}

.fx.data-table td {
  padding: 9px 15px;
  min-height: 50px;
  height: 50px;
  font-size: 16px;
  border-bottom: solid 1px var(--border-grey);
  /* border-left: solid 1px var(--border-grey); */
  letter-spacing: 0;
}

.fx.data-table tr td:first-child {
  border-left: solid 1px #fff;
}

.fx.data-table tr td:last-child {
  border-right: solid 1px #fff;
}

.fx.data-table tbody tr:hover {
  background-color: var(--bg-grey);
  cursor: pointer !important;
}

.fx.data-table tbody tr:hover td {
  color: #000;
}

.fx.data-table tbody tr:hover td:first-child {
  border-left: solid 1px var(--border-grey) !important;
}

.fx.data-table tbody tr:hover td:last-child {
  border-right: solid 1px var(--border-grey) !important;
}
/* 
.fx.data-table tr:last-child td {
  border-bottom: none !important;
} */
