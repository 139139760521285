.login {
  text-align: center;
  padding-top: 200px;
}

.login img {
  width: 400px;
}

.login > div:last-child {
  margin-top: 50px;
}

.login > .error {
  color: #db2828;
  font-weight: bold;
}
