.fx.pageHeader {
  /* position: sticky; */
  top: 0;
  background-color: #fff;
  z-index: 2;
  /* border-bottom: solid 1px var(--border-grey); */
  /* box-shadow: 0px 2px 10px 1px rgb(34 36 38 / 15%); */
}

.fx.pageHeader .fx.fnChangeButton,
.fx.pageHeader .fx.fnButton {
  text-align: center;
  padding: 8px 20px;
  /* background-color: var(--bg-grey); */
  margin-right: 1px;
  border-radius: 0 !important;
  font-size: 12px;

  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: initial !important;
  display: inline-block !important;

  /* border: solid 1px red !important; */
}

.fx.pageHeader .fx.fnButton {
  flex: 1 1 0;

  white-space: nowrap;
  text-overflow: ellipsis !important;
  overflow: hidden;
}

.fx.pageHeader .fx.fnButton:last-child {
  border-radius: 0px 5px 5px 0px !important;
}

.fx.pageHeader .fx.fnChangeButton {
  border-radius: 5px 0px 0px 5px !important;
}

.fx.pageHeader .fx.items > * {
  margin-left: 10px;
}

/* .fx.pageHeader .fx.fnButton:hover {
  cursor: pointer;
  background-color: var(--bg-greylight);
} */

/* .fx.pageHeader .fx.fnButton:nth-child(2) {
  flex: 1 1 0;
  border: solid 1px #253342;
  text-align: center;
  padding: 5px;
  background-color: #7cafd8;
  color: #fff;
} */

/* .fx.pageHeader .fx.fnButton::after {
  width: 22px;
  height: 22px;
  position: absolute;
  right: -7px;
  top: 5px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: scaleX(0.3) rotate(45deg);
  content: " ";
  cursor: pointer;
  background: #e5e5e5;
} */
