:root {
  --bg-grey: #f8f8f8;
  --bg-greylight: #f4f4f4;
  --border-grey: #ddd;
  --hl-grey: #f5f8fa;

  --hl-color: #0091ae;

  --bg-dark: #37352e;
  /* --bg-dark: #2185d0; */

  --primary: #4355be;
  --primary-light: #f6f4ff;
  --border-primary: #eeeaff;

  --red: #db2828;

  --olive: #8abc1e;

  --green: #6a803d;

  --black: #1e212a;

  --south: #ed151e;

  --error: #e23431;

  --active-shadow: 0px 0px 10px 1px var(--primary);
  --error-shadow: 0px 0px 4px 1px var(--error);

  --rotten: #fff5ee;
}

.red {
  color: var(--red);
}

.highlight {
  color: var(--primary);
}

.solidBackground {
  background-color: var(--primary-light);
}

.solidBorder {
  border: solid 1px var(--border-grey);
}

.solidBorderBottom {
  border-bottom: solid 1px var(--primary-light);
}

.solidBorderTop {
  border-top: solid 1px var(--primary-light);
}

.ui.dropdown .menu .selected.item,
.ui.dropdown .menu > .item:hover {
  background-color: var(--bg-grey) !important;
}

.ui.tabular.menu,
.ui.secondary.pointing.menu {
  border-bottom: 1px solid var(--border-grey) !important;
}

/* .ui.left.pointing.dropdown > .menu {
    z-index: 9999 !important;
  } */

.ui.tabular.menu .active.item {
  border: solid 1px var(--border-grey) !important;
  border-bottom: none !important;
}

.ui.tabular.menu .item:hover {
  /* background-color: var(--bg-grey) !important; */
  filter: brightness(1) !important;
}

.ui.breadcrumb a {
  text-transform: capitalize;
}

.ui.selection.dropdown a.ui.label {
  padding: 6px 8px;
  background-color: var(--primary) !important;
  color: #fff !important;
  border: none !important;
}

input:focus,
textarea:focus,
input:hover,
textarea:hover,
.ui.selection.dropdown:hover,
.ui.selection.dropdown.active {
  box-shadow: var(--active-shadow) !important;
  border-color: var(--primary) !important;
}

.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: var(--primary) !important;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: var(--primary) !important;
}
textarea.error,
.ui.dropdown.error:hover,
.ui.dropdown.error,
.ui.input.error > input,
.field.error > textarea {
  background-color: #fff !important;
  border-color: var(--error) !important;
  box-shadow: var(--error-shadow) !important;
  color: var(--error) !important;
}

.ui.dropdown.error > .default.text {
  color: #e6bdbc !important;
}

.ui.dropdown.error > .menu > .item {
  /* color: var(--error)!important; */
  color: rgba(0, 0, 0, 0.87) !important;
}

textarea::-webkit-input-placeholder {
  color: #c7c7c7;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #c7c7c7;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #c7c7c7;
}

textarea:-ms-input-placeholder {
  color: #c7c7c7;
}

textarea::placeholder {
  color: #c7c7c7;
}

textarea.error::-webkit-input-placeholder {
  color: #e6bdbc;
}

textarea.error:-moz-placeholder {
  /* Firefox 18- */
  color: #e6bdbc;
}

textarea.error::-moz-placeholder {
  /* Firefox 19+ */
  color: #e6bdbc;
}

textarea.error:-ms-input-placeholder {
  color: #e6bdbc;
}

textarea.error::placeholder {
  color: #e6bdbc;
}

/* .ui.basic.buttons > button.ui.active.button {
  background-color: var(--primary)!important;
  border: solid 1px var(--primary)!important;
  color: #fff!important;
} */

.ui.menu > a {
  color: rgba(0, 0, 0, 0.6) !important;
}

.ui.menu > a:hover {
  color: rgba(0, 0, 0) !important;
}

button.ui.basic.button {
  background-color: #fff !important;
}
