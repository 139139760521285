.fx.candidate-sidepanel {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;

  /* border-radius: 5px;
    border-left: solid 1px var(--border-grey);
    border-right: solid 1px var(--border-grey);
    border-bottom: solid 1px var(--border-grey); */
}

.fx.candidate-sidepanel tr:hover td,
.fx.candidate-sidepanel tr:hover th {
  border-bottom: dotted 1px var(--border-grey);
  border-top: dotted 1px var(--border-grey);
  /* cursor: pointer!important; */
}

.fx.candidate-sidepanel td,
.fx.candidate-sidepanel th {
  border: solid 1px rgba(0, 0, 0, 0);
}

.fx.candidate-sidepanel th {
  /* background-color: #e8e8e8; */
  text-align: right;
  padding: 10px 0px;
  max-width: 120px;
  min-width: 120px;
  width: 120px;
  color: rgba(0, 0, 0, 0.6);
  vertical-align: top;

  /* border-top: solid 1px var(--border-grey); */
}

.fx.candidate-sidepanel td {
  padding: 10px 12px;
  width: 280px !important;
  max-width: 280px !important;
  /* overflow: hidden; */
  word-wrap: break-word;
  text-overflow: ellipsis;

  /* border-top: solid 1px var(--border-grey); */
}
